import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "currentColor",
  viewBox: "0 0 80 80"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M35.471 57.18a4.93 4.93 0 1 0-.02 9.859 4.93 4.93 0 0 0 .02-9.859m0 7.47a2.54 2.54 0 1 1-.02-5.082 2.54 2.54 0 0 1 .02 5.082m18.17-7.47a4.93 4.93 0 1 0 1.878.371 4.9 4.9 0 0 0-1.888-.37h.01zm0 7.47a2.54 2.54 0 1 1 .965-.194 2.6 2.6 0 0 1-.975.194zm-19.46-32.36h20.73a1.2 1.2 0 1 0 0-2.39h-20.73a1.197 1.197 0 0 0-1.31 1.195 1.2 1.2 0 0 0 1.31 1.195m19.6 5.12h-18.47a1.2 1.2 0 1 0 0 2.39h18.47a1.2 1.2 0 1 0 0-2.39m15-14a2.64 2.64 0 0 0-2-1h-45.31l-.95-4.61a2.67 2.67 0 0 0-1.54-1.9l-6.31-2.8a1.1 1.1 0 0 0-.47-.1q-.227.003-.44.08a1.21 1.21 0 0 0-.68 1.56c.12.292.35.526.64.65l6.47 2.81v.13l7 34a2.65 2.65 0 0 0 2.58 2.11h34.7a1.2 1.2 0 0 0 0-2.4h-34.87l-.95-4.63h36.08a2.65 2.65 0 0 0 2.59-2.11l4.05-19.64a2.65 2.65 0 0 0-.54-2.2zm-5.84 21.51h-36.79l-4.15-20.14h45.09z" }, null, -1)
  ])))
}
export default { render: render }